<template>
  <div v-if="currentPlatform !== null">
    <div class="content content_in">
      <ArbitragePlatform
        @editPlatform="editPlatrorm"
        :landings="landings"
        :isEdit="true"
        :editedPlatform="currentPlatform"
        v-if="!currentPlatform.site"
      />
      <SitePlatform
        @editPlatform="editPlatrorm"
        :editedPlatform="currentPlatform"
        :isEdit="true"
        v-else
      />
    </div>
    <notifications group="error" />
  </div>
</template>

<script>
import ServiceSubAccs from "@/api/subaccsService";
import "@/assets/css/_page-platform.scss";
import "@/assets/css/_section.scss";
import ArbitragePlatform from "@/components/ArbitragePlatform.vue";
import SitePlatform from "@/components/SitePlatform.vue";

export default {
  name: "EditPlatform",
  components: {
    ArbitragePlatform,
    SitePlatform,
  },
  data() {
    return {
      landings: [],
      order: "grid",
      id: null,
      currentPlatform: null,
    };
  },
  mounted() {
    this.id = this.$route.params.id;
    ServiceSubAccs.getLandings().then((res) => {
      if (res.status === 200) {
        this.landings = res.data;
      }
    });
    ServiceSubAccs.getSubAcc(this.id).then((res) => {
      if (res.status === 200) {
        this.currentPlatform = res.data;
      }
    });
  },
  methods: {
    closeModal() {
      this.$modal.hide("massModal");
    },
    editPlatrorm(payload) {
      let params = {
        // general variables for platforms
        name: this.currentPlatform.name,
        complexity: +this.currentPlatform.complexity,
        tb_subscription: this.currentPlatform.tb_subscription,
        tb_complexity: this.currentPlatform.tb_complexity,
        tb_subscription_url: this.currentPlatform.tb_subscription_url,
        tb_complexity_url: this.currentPlatform.tb_complexity_url,
        adult: +this.currentPlatform.adult,
        fullscreen: +this.currentPlatform.fullscreen,
        lands: this.currentPlatform.lands || [],
        postback_url: this.currentPlatform.postback_url,
      };
      if (!this.currentPlatform.site) {
        params.payment_model = +this.currentPlatform.payment_model.value;
      }
      params = Object.assign(params, payload);

      ServiceSubAccs.updateSubAcc({ id: this.$route.params.id, params }).then(
        (res) => {
          if (res) {
            if (res.status === 200) {
              this.$notify({
                group: "error",
                title: this.$t("sent_success"),
                text: res.message,
                ignoreDuplicates: true,
                type: "success",
              });
              this.$emit("createdTicket", res.data.id);
              this.$emit("closeModal");
            } else {
              this.$notify({
                group: "error",
                title: this.$t("sent_error"),
                text: res.message,
                ignoreDuplicates: true,
                type: "error",
              });
            }
          }
        }
      );
    },
  },
};
</script>



